<template>
  <v-container fluid>
    <PageNavigation :items="items" />

    <v-row>
      <v-col cols="12">
        <base-material-card
          :title="$vuetify.lang.t('$vuetify.picking')"
          icon="mdi-apple-finder"
        >
          <v-row class="">
            <v-col cols="12" lg="6" md="6" sm="6">
              <v-text-field
                :label="$vuetify.lang.t('$vuetify.searchOrder')"
                append-icon="mdi-magnify"
                clearable
                v-model="searchOrder"
                hint="Click search to confirm"
                @click:clear="handleClear"
                @click:append="handleSearchOrder"
              />
            </v-col>

            <v-col cols="12" lg="6" md="6" sm="6">
              <v-text-field
                :label="$vuetify.lang.t('$vuetify.searchPO')"
                append-icon="mdi-magnify"
                clearable
                v-model="searchPO"
                hint="Click search to confirm"
                @click:clear="handleClear"
                @click:append="handleSearchPO"
              />
            </v-col>

            <v-col cols="12" lg="6" md="6" sm="6">
              <v-menu
                v-model="menu"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                :nudge-top="30"
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="dateRangeText"
                    :label="
                      $vuetify.lang.t('$vuetify.startDate') +
                      ' ~ ' +
                      $vuetify.lang.t('$vuetify.endDate')
                    "
                    prepend-icon=""
                    prepend-inner-icon="mdi-calendar"
                    readonly
                    clearable
                    v-bind="attrs"
                    v-on="on"
                    @click:clear="dates = null"
                  />
                </template>

                <v-date-picker
                  v-model="dates"
                  @change="handleDate"
                  @input="dates.length == 2 ? (menu = false) : true"
                  range
                  class="ma-0 pa-0"
                  :locale="
                    this.$vuetify.lang.current == 'zhHans' ? 'zh-cn' : 'en'
                  "
                />
              </v-menu>
            </v-col>

            <v-col cols="12" lg="6" md="6" sm="6">
              <v-select
                :items="statuses"
                :label="$vuetify.lang.t('$vuetify.status')"
                @change="(item) => handleStatus(item)"
                clearable
              />
            </v-col>

            <v-spacer />

            <v-col
              cols="12"
              align-self="center"
              class="ma-0 d-flex justify-end"
            >
              <v-btn
                class="ma-0"
                color="green"
                elevation="2"
                @click="handleDialog"
                :width="$vuetify.breakpoint.mdAndUp ? '' : '100%'"
                v-if="permissionCheck('add')"
              >
                <v-icon left> mdi-account-plus </v-icon>
                {{ $vuetify.lang.t("$vuetify.add") }}
              </v-btn>
            </v-col>
          </v-row>

          <v-row class="mt-4">
            <v-col cols="12">
              <GeneralTable
                :head="headers"
                :data="datas"
                :dialog.sync="dialog"
                v-on:edititem="handleEditDialog"
                v-on:audit="handleAuditDialog"
                v-on:abolish="handleDeleteDialog"
                v-on:deliveryCompleted="handleDeliveryDialog"
                v-on:saleReturn="handleSaleReturnDialog"
                :tableLoading.sync="tableLoading"
              />
            </v-col>
          </v-row>
        </base-material-card>

        <StockOutCRUD
          :dialog.sync="dialog"
          :myObj="obj"
          :selectedProduct.sync="selectedProduct"
          :addAction.sync="addAction"
          :editAction.sync="editAction"
          :auditAction.sync="auditAction"
          :abolishAction.sync="abolishAction"
          @handleData="handleStockOut"
          v-on:selectProduct="handleSelect"
          v-on:getData="getData"
        />

        <SelectProduct
          :dialogProduct.sync="dialogProduct"
          :selected.sync="selectedProduct"
          @getSelectedProduct="handleSelectProduct"
        />

        <AbolishStockOutDialog
          :dialogDelete.sync="dialogDelete"
          :deleteObj="deleteObj"
          @handleDelete="handleDeleteConfirm"
          v-on:edititem="handleDialog"
          v-on:deleteitem="handleDeleteDialog"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import {
  pickingList,
  pickingUpsert,
  pickingInfo,
  pickingCheck,
  pickingCancel,
  receive_order,
  upsertReceive,
  checkReceive,
  cancelReceive,
  infoReceive,
  putaway,
} from "@/api/purchase";
import { profilePicUpload } from "@/api/company";

const clone = (obj) => Object.assign({}, obj);
const renameKey = (object, key, newKey) => {
  const clonedObj = clone(object);
  const targetKey = clonedObj[key];

  delete clonedObj[key];
  clonedObj[newKey] = targetKey;
  return clonedObj;
};

const newObj = () => {
  return {
    id: "",
    po: "",
    type: "",
    sale_po: "",
    product_po: "",
    relation_id: "",
    warehouse_id: "",
    warehouse_info: {},
    status: 0,
    products: [
      {
        id: "",
        number: null,
        nick_name: "",
        image: "",
        type: 1,
        code: "",
      },
    ],
    attach: [],
  };
};

const newSearch = () => {
  return {
    order_po: "",
    po: "",
    bt: "",
    et: "",
    status: 0,
    skip: 0,
  };
};

export default {
  name: "StockOut",
  components: {
    GeneralTable: () => import("@/components/tableImg"),
    StockOutCRUD: () => import("@/components/purchase/stockOutCRUD"),
    SelectProduct: () => import("@/components/orders/selectProduct"),
    PageNavigation: () => import("@/components/pageNavigation"),
    AbolishStockOutDialog: () =>
      import("@/components/purchase/abolishStockOutDialog"),
  },
  data() {
    return {
      dates: [],
      wait: false,
      tableLoading: true,
      dialogProduct: false,
      menu: false,
      dialog: false,
      dialogDelete: false,
      dialogStockIn: false,
      addAction: false,
      editAction: false,
      auditAction: false,
      abolishAction: false,
      searchPO: "",
      searchOrder: "",
      searchForm: newSearch(),
      obj: newObj(),
      deleteObj: newObj(),
      datas: [],
      selectedProduct: [],
      items: [
        { text: this.$vuetify.lang.t("$vuetify.home") },
        { text: this.$vuetify.lang.t("$vuetify.purchase") },
        { text: this.$vuetify.lang.t("$vuetify.picking") },
      ],
      statuses: [
        {
          text: this.$vuetify.lang.t("$vuetify.orderCheck"),
          value: 1,
        },
        {
          text: this.$vuetify.lang.t("$vuetify.complete"),
          value: 2,
        },
        {
          text: this.$vuetify.lang.t("$vuetify.OutOfStock"),
          value: 3,
        },
        {
          text: this.$vuetify.lang.t("$vuetify.canceled"),
          value: -1,
        },
      ],
      headers: [
        {
          text: this.$vuetify.lang.t("$vuetify.itemNo"),
          value: "itemNo",
          width: "100",
          sortable: false,
          align: "center",
        },
        {
          text: this.$vuetify.lang.t("$vuetify.permitNo"),
          value: "po",
          width: "160",
        },
        {
          text: this.$vuetify.lang.t("$vuetify.productCategory"),
          value: "stockOutProductCategory",
          sortable: false,
          align: "center",
          width: "140",
        },
        {
          text: this.$vuetify.lang.t("$vuetify.date"),
          value: "gen_time",
          sortable: false,
          width: "180",
        },
        {
          text: this.$vuetify.lang.t("$vuetify.note"),
          value: "note",
          sortable: false,
        },
        {
          text: this.$vuetify.lang.t("$vuetify.totalItem"),
          value: "totalProductType",
          sortable: false,
          align: "center",
          width: "140",
        },
        {
          text: this.$vuetify.lang.t("$vuetify.goodsTotal"),
          value: "totalQty",
          sortable: false,
          align: "center",
          width: "140",
        },
        {
          text: "Status",
          value: "stockOutStatus",
          width: "100",
          sortable: false,
        },
        {
          text: this.$vuetify.lang.t("$vuetify.opt"),
          value: "stockOutActions",
          width: "120",
          sortable: false,
          align: "center",
        },
      ],
    };
  },
  methods: {
    getData() {
      pickingList(this.searchForm)
        .then((data) => {
          this.datas = [...data.items];

          this.datas.forEach((p, k) => {
            p.itemNo = k + 1;
            p.totalProductType = p.products.length;
            p.totalQty = 0;

            p.products.forEach((i, l) => {
              p.stockOutProductName = i.name;
              p.totalQty += i.real_number;
            });
          });

          this.tableLoading = false;
        })
        .catch((err) => {
          this.tableLoading = false;
          this.datas = [];
        });
    },
    setProduct(obj, files) {
      if (this.auditAction == true) {
        var auditProduct = {};
        obj.products.forEach((item) => {
          auditProduct[item.id] = parseFloat(item.real_number);
        });
      } else {
        var product = [];
        obj.products.forEach((item) => {
          var json = {};
          json["id"] = item.id;
          json["number"] = parseFloat(item.number);
          json["type"] = 1;
          json["image"] = item.image;
          product.push(json);
        });
      }

      if (this.auditAction == true) {
        obj.products = JSON.stringify(auditProduct);
      } else {
        obj.products = JSON.stringify(product);
      }

      const arr = [];
      obj.attach.length > 0 ? obj.attach.forEach((p) => arr.push(p.md5)) : "";
      if (files != undefined) {
        const fileForm = new FormData();
        fileForm.append("file", files);

        profilePicUpload(fileForm).then((res) => {
          arr.push(res.data.md5);
          obj.attach = JSON.stringify(arr);
          console.log("attachobj", obj);
          this.obj = obj;
          this.wait = true;
        });
      } else {
        obj.attach = JSON.stringify(arr);
        this.obj = obj;
        this.wait = true;
      }
      ///// return obj;
    },
    delPicking(obj) {
      var data = {
        id: obj.id,
        cancel_reason: obj.cancel_reason,
      };
      pickingCancel(data).then((response) => {
        if (response.meta === 0) {
          this.getData();
        }
      });
    },
    resetObj() {
      (this.obj.id = ""),
        (this.obj.po = ""),
        (this.obj.product_po = ""),
        (this.obj.relation_id = ""),
        (this.obj.products = null),
        (this.obj.note = ""),
        (this.obj.attach = []),
        (this.obj.type = ""),
        (this.obj.warehouse_id = ""),
        (this.obj.warehouse_info = {}),
        (this.obj.status = 0),
        (this.obj.products = null);
      this.obj.returnID = "";
      this.obj.returnStatus = "";
    },
    handleDeleteDialog(obj) {
      this.dialogDelete = true;
      this.deleteObj = obj;
    },
    handleDeleteConfirm(obj) {
      this.dialogDelete = false;
      this.delPicking(obj);
    },
    handleDialog() {
      this.dialog = true;
      this.addAction = true;
      this.resetObj();
      this.selectedProduct = [];
    },
    handleEditDialog(obj) {
      if (this.permissionCheck("modify")) {
        this.dialog = true;
        this.editAction = true;
        this.obj = obj;
        this.addToSelectedProduct(obj);
      }
    },
    handleAuditDialog(obj) {
      this.dialog = true;
      this.auditAction = true;
      this.obj = obj;
      this.addToSelectedProduct(obj);
    },
    handleAbolishDialog(obj) {
      this.dialog = true;
      this.abolishAction = true;
      this.obj = obj;
      this.addToSelectedProduct(obj);
    },
    handleStockOut(obj, files) {
      this.dialog = false;
      //this.upsertPicking(obj, files);
      this.setProduct(obj, files);
      //this.resetAction();
      this.selectedProduct = [];
    },
    handleSelect() {
      this.dialogProduct = true;
    },
    handleSelectProduct(obj) {
      this.dialogProduct = false;

      for (var i = 0; i < obj.length; i++) {
        this.selectedProduct.push(obj[i]);
        this.selectedProduct = [...new Set(this.selectedProduct)];
      }
    },
    handleDeliveryDialog(obj) {
      this.dialog = true;
      this.obj = obj;
      this.addToSelectedProduct(obj);
    },
    handleSaleReturnDialog(obj, returnID, returnStatus) {
      this.obj = obj;
      this.addToSelectedProduct(obj);
      this.obj.returnID = returnID;
      this.obj.returnStatus = returnStatus;
      this.dialog = true;
    },
    resetAction() {
      this.addAction = false;
      this.editAction = false;
      this.auditAction = false;
      this.abolishAction = false;
    },
    addToSelectedProduct(obj) {
      this.selectedProduct = this.obj.products;
    },
    handleDate() {
      this.searchForm.bt = Array.isArray(this.dates) ? this.dates[0] : "";
      this.searchForm.et = Array.isArray(this.dates) ? this.dates[1] : "";

      this.getData();
    },
    handleStatus(item) {
      this.searchForm.status = item;

      this.getData();
    },
    handleSearchPO() {
      if (this.searchPO != null) {
        this.searchForm.po = this.searchPO;
      }

      this.getData();
    },
    handleSearchOrder() {
      if (this.searchOrder != null) {
        this.searchForm.order_po = this.searchOrder;
      }

      this.getData();
    },
    handleClear() {
      this.searchForm = newSearch();

      this.getData();
    },
  },
  mounted() {
    this.getData();
  },
  computed: {
    dateRangeText: {
      get: function () {
        return this.dates != null ? this.dates.join(" ~ ") : "";
      },
      set: function (value) {
        if (value == null) {
          this.searchForm = newSearch();

          this.getData();
        }
      },
    },
  },
  watch: {
    dialog() {
      this.getData();
    },
    wait() {
      if (this.wait) {
        if (this.auditAction == false && this.obj.status != 2) {
          console.log("upsert", this.obj);
          pickingUpsert(this.obj).then((response) => {
            if (response.meta === 0) {
              this.getData();
            }
          });
        } else {
          if (this.obj.status == 2) {
            receive_order({ id: this.obj.id }).then((res) => {
              this.getData();
            });
          } else {
            pickingCheck(this.obj).then((response) => {
              if (response.meta === 0) {
                this.getData();
              }
            });
          }
        }
        this.resetAction();
      }
    },
  },
};
</script>